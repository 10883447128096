import * as React from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
//import { useNavigate } from 'react-router-dom';
import {  Stack, Box, Button, Grid, Snackbar, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiAlert from '@mui/material/Alert';

/**
 * This is the main react component for the home page. It will display the name and booking form fields
 * 
 * @returns ReactComponent
 */
const Login = (props) => {
    // Set the navigate function so we can redirect to the new page after submit
    //const navigate = useNavigate();

    /**
     * Object to update the snackbar type of message after uploading photos
     */
     const successObj = {
      state: "success",
      message: "",
    };

    /**
     * loading state is to track the display of the circularprogress spinner
     * success state is used to display the type and message in the snackbar, it uses the successObj as it's value
     * open state is used to track the display of the snackbar
     */
    const [success, setSuccess] = React.useState(successObj);
    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    /**
     * Create a reusable and customizable alert to be displayed inside the snackbar
     */
    const Alert = React.forwardRef(function Alert(
        props,
        ref,
        ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    /**
     * handele closing the snackbar
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Handle the submission of the form. Bubble the values up a level to the parent by calling the 
     * passed in state function and then navigate to the upload component
     * @param {event} event 
     */
    const handleSubmit = event => {
        event.preventDefault();

        const firebaseConfig = {
          apiKey: "AIzaSyBQAi0xUroWaiW7QxbLIIe89gS3KPDFQqo",
          authDomain: "srht-pws-dev.firebaseapp.com",
        };
    
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);

        const logInWithEmailAndPassword = async (email, password) => {
          try {
            await signInWithEmailAndPassword(auth, email, password);
            props.setLogin(true);
          } catch (err) {
            console.error(err);
            setSuccess({
              state: "error",
              message: "Invlide email or password",
            });
            setOpen(true);
          }
        };

        var email = event.target.email.value;
        var password = event.target.password.value;

        if((email.length > 0 && email.indexOf('@') !== -1) && (password.length > 0))
        {
          logInWithEmailAndPassword(email, password);
        } else {
          setSuccess({
            state: "error",
            message: "Invlide email or password",
          });
          setOpen(true);
        }
    };

    return (
        <React.Fragment>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                sx={{ width: "100%", mt: "20px" }}
            >
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                        <Stack spacing={2}>
                           <FormControl variant="outlined">
                              <InputLabel htmlFor="email">Email</InputLabel>
                              <OutlinedInput id="email" size="small" label="Email" />
                            </FormControl>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="password">Password</InputLabel>
                              <OutlinedInput id="password" type={showPassword ? 'text' : 'password'}
                              label="Password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              />
                            </FormControl>
                            <Button type="submit" variant="contained">Submit</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={success.state} sx={{ width: '100%' }}>
                {success.message}
              </Alert>
            </Snackbar>
        </React.Fragment>
    );
};

export default Login;