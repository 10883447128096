import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const TopBar = (props) => {
  const navigate = useNavigate();

  const handleNewBookingClick = () => {
    props.appBarLogin("");
    navigate("/");
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Button color="secondary" variant="contained" onClick={handleNewBookingClick}>New Booking</Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align="center">
            SRHT - Photos with Sanata - File Uploader ({process.env.REACT_APP_VERSION})
          </Typography>
          {props.refNum.length > 0 && <Typography component="div" variant="h9">{props.name} - {props.refNum}</Typography>}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;