import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {  Stack, Box, TextField, Button, Grid } from '@mui/material';

/**
 * This is the main react component for the home page. It will display the name and booking form fields
 * 
 * @param {stateHandler} bookingRefSubmit 
 * @returns ReactComponent
 */
const Home = ({bookingRefSubmit}) => {
    // Set the navigate function so we can redirect to the new page after submit
    const navigate = useNavigate();

    /**
     * Handle the submission of the form. Bubble the values up a level to the parent by calling the 
     * passed in state function and then navigate to the upload component
     * @param {event} event 
     */
    const handleSubmit = event => {
        event.preventDefault();
        bookingRefSubmit(event.target.booking_ref.value, event.target.name.value);
        navigate("/upload");
    };

    return (
        <React.Fragment>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                sx={{ width: "100%", mt: "20px" }}
            >
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                        <Stack spacing={2}>
                            <TextField id="name" variant="filled" size="small" label="Name" />
                            <TextField id="booking_ref" variant="filled" label="Booking Ref #"/>
                            <Button type="submit" variant="contained">Submit</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default Home;