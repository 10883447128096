import * as React from 'react';
import { Button, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const UploadButton = (props) => {
    /**
     * Object to update the snackbar type of message after uploading photos
     */
    const successObj = {
        state: "success",
        message: "",
    };

    const callReturns = {
        success: {
            count: 0
        },
        fail: {
            count: 0,
            lastMessage: "",
        },
    };

    /**
     * loading state is to track the display of the circularprogress spinner
     * success state is used to display the type and message in the snackbar, it uses the successObj as it's value
     * open state is used to track the display of the snackbar
     */
    const [uploadState, setUploadState] = React.useState({
        loading: false,
        dUpload: false,
        success: successObj,
        open: false
    });

    /**
     * Create a reusable and customizable alert to be displayed inside the snackbar
     */
    const Alert = React.forwardRef(function Alert(
        props,
        ref,
        ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    /**
     * handele closing the snackbar
     */
    const handleClose = () => {
        setUploadState({
            open: false,
            loading: false,
            success: successObj,
            dUpload: false
        });
    };

    /**
     * Primary component to handle uploading the photos / calling the API to upload the photos. The API is called once with multiple photos in the payload.
     * @param {*} event 
     */
    const handleUpload = async (event) => {
        // Show the spinner and disble the upload button
        setUploadState({
            loading: true,
            dUpload: true,
            success: successObj,
            open: false
        });

        props.setStartUpload(true);

        /**
         * Set the form content type, create the form data object and append all the photos / files data to it for a single
         * data payload when posting to the API
         */
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: false,
        };

        for(let i = 0; i < props.files.length; i++) {
            let data = new FormData();
            data.append('files', props.files[i], props.files[i].name);

            await axios.post(`${process.env.REACT_APP_APISERVER}/upload?bookingid=${props.refNum}&name=${props.name}`, data, options).then( res => {
                callReturns.success.count++;
            }).catch( error => {
                callReturns.fail = {
                    count: callReturns.fail.count+1,
                    lastMessage: error.message,
                };
                console.error(error);
            });

            if(callReturns.fail.count > 0) {
                setUploadState({
                    open: true,
                    loading: false,
                    dUpload: false,
                    success: {
                        state: "error",
                        message: callReturns.fail.lastMessage,
                    }
                });

                break;
            }
        }

        if(callReturns.fail.count === 0 && callReturns.success.count > 0) {
            setUploadState({
                open: true,
                loading: false,
                dUpload: false,
                success: {
                    state: "success",
                    message: "Photos were successfully uploaded"
                }
            });

            props.setFilesUploaded(callReturns.success.count);
        }
    }

    return (
        <React.Fragment>
            <div style={{width: 'fit-content', blockSize: 'fit-content'}}>
            <Button variant="contained" component="span" onClick={handleUpload} disabled={uploadState.dUpload}>Upload Photos</Button>
            {uploadState.loading && <CircularProgress
                size={24}
                sx={{
                    position: 'relative',
                    top: '5px',
                    left: '-80px',
                }}
            />
            }
            </div>

        <Snackbar open={uploadState.open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={uploadState.success.state} sx={{ width: '100%' }}>
                {uploadState.success.message}
            </Alert>
        </Snackbar>
        </React.Fragment>
    )
};

export default UploadButton;