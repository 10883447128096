import * as React from 'react';
import Home from './components/home/home';
import Upload from './components/upload/upload';
import AppBar from './components/appbar/appbar';
import Login from './components/login/login';
import { Routes, Route } from 'react-router-dom';

const App = () => {
  const [bookingRef, setBookingRef] = React.useState('');
  const [name, setName] = React.useState('');
  const [login, setLogin] = React.useState(false);

  const handleSetBookingRef = (bookingRef, name) => {
    setBookingRef(bookingRef);
    setName(name);
  }

  if(login) {
    return (
      <div style={{ height: "100%" }}>
          <AppBar refNum={bookingRef} name={name} appBarLogin={handleSetBookingRef} />
          <Routes>
              <Route path="/" element={<Home bookingRefSubmit={handleSetBookingRef} />} />
              <Route path="/upload" element={<Upload refNum={bookingRef} name={name} />} />
          </Routes>
      </div>
    );
  } else {
    return (
      <div style={{ height: "100%"}}>
        <Login setLogin={setLogin} />
      </div>
    );
  }
}

export default App;