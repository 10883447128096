import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ImageList, ImageListItem, Box, Stack, Grid, Typography } from '@mui/material';
import UploadButton from './uploadButton';

/**
 * This is the main react component for selecting the photos and uploading them.
 * 
 * @param {*} props (refNum, name) 
 * @returns ReactComponent
 */
const Upload = (props) => {
    // Set the navigate function so we can redirect to the new page after submit
    const navigate = useNavigate();

    /**
     * States to track the photos that were selected and need to be uploaded
     * 
     * itemData is an array that will contain the blob image data and the title for display in the browser
     * files is an array that will contain the unmodified file objects from the browser to be added to the upload API call
     */
    const [files, setFiles] = React.useState([]);
    const [startUpload, setStartUpload] = React.useState(false);
    const [filesUploaded, setFilesUploaded] = React.useState(0);

    /**
     * Before loading everything, check to make sure that the refnum is set, if not, go back and try again
     */
    React.useEffect(() => {
        if(props.refNum === '') {
            navigate('/');
        }
    });

    /**
     * Handle the select photos button click. Read the photos array / object from the <input type=file>
     * and set the itemdata state so we can display them in the browswer for confirmation. Also save the unmodified file(s)
     * object to the files state for later.
     * 
     * @param {event} event 
     */
    const onFileUpload = async (event) => {
        for(let i = 0; i < event.target.files.length; i++){
            (function(file){
              setFiles(current => [...current, file]);
            })(event.target.files[i]);     
        }
    }

    /**
     * If the photos have been selected and we updated the files state then let's display the upload photos button
     */
    let uploadB = '';

    if(files.length > 0) {
        uploadB = <UploadButton files={files} refNum={props.refNum} name={props.name} setStartUpload={setStartUpload} setFilesUploaded={setFilesUploaded}/>
    }

    return (
        <React.Fragment>
            <Box sx={{ m: 1, position: 'relative' }}>
            <form>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="uploaded_pics"
                    type="file"
                    multiple
                    onChange={onFileUpload}
                />
                 <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                        <Stack spacing={2} direction="row">
                          {files.length > 0 && <Typography align="center" style={{color: '#2b4257', fontSize: '14px', alignItems: 'center', display: 'flex'}}>{files.length} photos selected</Typography>}
                            <label htmlFor='uploaded_pics'>
                                <Button variant="contained" component="span" disabled={startUpload}>Select Photos</Button>
                            </label>
                            {uploadB}
                            {filesUploaded > 0 && <Typography align="center" style={{color: '#2b4257', fontSize: '14px', alignItems: 'center', display: 'flex'}}>{files.length} photos uploaded</Typography>}
                        </Stack>
                    </Grid>
                </Grid>
                
            </form>
            </Box>
            <ImageList cols={3} rowHeight={250}>
                {files.map((file) => (
                    <ImageListItem key={file.name}>
                    <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        loading="lazy"
                    />
                    </ImageListItem>
                ))}
            </ImageList>
        </React.Fragment>
    );
};

export default Upload;